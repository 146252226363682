@font-face {
    font-family: 'Inter-Regular-9';
    src: url(../assets/fonts/Inter-Black-3.otf) format("otf");
}

.wrapper {
    position: relative;
}

html {
    font-size: 16px;
}

.center {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
}

.blue-line {
    width: 432px;
    border: 1px solid #00F0FF;
    position: relative;
    height: 0;
}

.blue-line::after {
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 0px;
    height: 0px;
    border: 4px solid;
    border-color: transparent #00F0FF transparent transparent;
}

.blue-line::before {
    position: absolute;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 0px;
    height: 0px;
    border: 4px solid;
    border-color: transparent transparent transparent #00F0FF;
}

.textCenter {
    text-align: center;
}

.blockCenter {
    margin: 0 auto;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;

}

.desktopPage {
    background-color: #EFEFEF;
    overflow: hidden;
}

.desktopPage .Navbar_header {
    width: 100%;
    position: relative;
    height: 253px;
}

.desktopPage .Navbar_menu {
    display: none;
    position: fixed;
    left: -6px;
    top: 8%;
    width: 50px;
    height: auto;
    z-index: 99;
    cursor: pointer;
}

.desktopPage .topnav {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 50px;
}

.desktopPage .bottomnav {
    display: flex;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 49px;
}

.desktopPage .Navbar_logo {
    width: 28.09325rem;
    height: 3.75rem;
    position: absolute;
    left: 50%;
    top: 3.06rem;
    transform: translateX(-50%);
}

.desktopPage .toplogo {
    width: 28.09325rem;
    height: 3.75rem;
    /* position: absolute;
    left: 50%;
    top: 3.06rem;
    transform: translateX(-50%); */
}


.desktopPage .Navbar_text {
    color: #484848;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.desktopPage .Sec1_text {
    color: #50698B;
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.desktopPage .Sec2_bg {
    width: 100%;
    position: relative;
    height: auto;
}

.desktopPage .sec3Text {
    font-size: 28px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 42px;
    letter-spacing: 7px;
    text-align: left;
}

.nav_content {
    height: 256;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1000;
}

.nav_content.h5 {
    position: fixed;
}

.nav_content .logo {
    display: none;
}

.nav_content .close {
    display: none;
}

.nav_content .Navbar_text {
    /* position: absolute; */
    cursor: pointer;
}

/* .nav_content .Navbar_text:nth-child(1) {
    left: 204px; top: 74px
}
.nav_content .Navbar_text:nth-child(2) {
    left: 365px; top: 160px
}
.nav_content .Navbar_text:nth-child(3) {
    left: 596px; top: 160px
}
.nav_content .Navbar_text:nth-child(4) {
    left: 787px; top: 160px
}
.nav_content .Navbar_text:nth-child(5) {
    left: 984px; top: 160px
}
.nav_content .Navbar_text:nth-child(6) {
    left: 1149px; top: 74px
} */
.section1 {
    height: 468px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4% 0 4%;
    box-sizing: border-box;
}

.section1 .left-text-box {
    position: relative;
    /* left: 12%;
    top: 159px; */
    font-size: 48px;
    color: rgb(80, 105, 139);
    text-align: center;
    width: 65%;
    height: 100%;
}

.section1 .left-text-box .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.section1 .left-text-box .p {
    margin-bottom: 26px;
}

.section1 .left-text-box div {
    position: relative;
    z-index: 2;
}

.section1 .right-img {
    height: 87%;
    width: 35%;
    position: relative;
}

.section2 {
    font-size: 0;
    padding-bottom: 100px;
}

.section2 .main-box {
    background: #fff;
    padding-bottom: 280px;
    position: relative;
}

.section2 .Sec2_text {
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 3;
}

.section2 .Sec2_text.one {
    width: 78%;
}

.section2 .Sec2_bg {
    height: auto;
}

.section2 .img-center {
    width: 602px;
    height: 602px;
    position: relative;
    z-index: 7;
}

.section2 .img-center .Sec2_img {
    width: 100%;
    height: 100%;
}

.section2 .img-center .Sec2_img_txt {
    position: absolute;
    top: 233px;
    left: 50%;
    transform: translateX(-50%);
}

.section2 .bottom-img-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;
}

.section2 .bottom-img-box img {
    margin: 0 4% 40px;
    width: 450px;
    position: relative;
    z-index: 7;
}

.section3 {
    background: #fff url(../assets/images/Group\ 48095405.png) no-repeat center top;
    background-size: 100% 100%;
    position: relative;
}

.section3::before {
    /* position: absolute;
    left: 0;
    top: 25px;
    content: "";
    background: url(./adobestock_568050974.png) no-repeat center top;
    background-size: 100% auto;
    width: 100%;
    height: 100%; */
}

.section3 .whitetxt {
    text-shadow: rgba(255, 255, 255, 0.9) 0px 3px 8px;
    font-size: 24px;
    width: 100%;
    text-align: center;
    line-height: 51px;
    position: relative;
    color: #fff;
    letter-spacing: 15px;
    margin-top: 20px;
    font-weight: 100;
}

.section3 .whitetxt b {
    font-weight: bold;
}

.section3 .Sec3_bg {
    width: 100%;
    height: 100%;
}

.section3 .main-box {
    /* position:absolute;height:100%;left:0;top:0; */
    padding: 1px 0 400px 0;
    width: 100%;
    /* background: linear-gradient(341deg, #974BFF 0%, #153B8E 26%, #8F00B8 56%, #5132CD 78%, #492D79 89%); */
}

.section3 .txtbox {
    z-index: 5;
    position: relative;
}

.section3 .txtbox .img1 {
    width: 40%;
    margin: 100px auto 0px;
    display: block;
}

.section3 .txtbox .img2 {
    width: 60%;
    margin: 50px auto 0px;
    display: block;
}

.section3 .txtbox .img3 {
    width: 48%;
    margin: 50px auto 0px;
    display: block;
}

.section3 .txtbox .img4 {
    width: 51%;
    margin: 10px auto 0px;
    display: block;
}

.section3 .txtbox .blue-line {
    width: 56%;
    margin: 60px auto 0px;
    display: block;
}

.section3 .main-img-box {
    max-width: 1440px;
    margin: 0px auto 0;
    position: relative;
}

.section3 .main-img-box .topbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.section3 .main-img-box .topbox .center-img {
    width: 40%;
    top: 50px;
    z-index: 3
}

.section3 .main-img-box .ball {
    width: 30%;
    position: relative;
}

.section3 .main-img-box .ball img {
    width: 100%;
    height: 100%;
}

.section3 .main-img-box .ball span {
    left: 50%;
    top: 50%;
    position: absolute;
    font-size: 40px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    letter-spacing: 20px;
    white-space: nowrap;
}

.section3 .main-img-box .ball.ball-left {
}

.section3 .main-img-box .ball.ball-left span {
    transform: translate(-44%, -50%);
}

.section3 .main-img-box .ball.ball-right {
    top: 50px;
}

.section3 .main-img-box .ball.ball-right span {
    transform: translate(-41%, -73%);
}

.section3 .main-img-box .ball.ball-center {
    margin-top: -180px;
}

.section3 .main-img-box .ball.ball-center span {
    transform: translate(-38%, -34%);
}

.section3 .title-sub {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 12px;
    background: linear-gradient(89deg, #E8ECEF 0%, #5DFEEB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 60px auto 44px;
    display: block;
    width: 260px;
}

.section3 .title-sub .blue-line {
    /* margin-left: -10px; */
}

.section3 .title-sub .blue-line {
    width: 100%;
    margin-top: 25px;
}

.section3 .stone-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section3 .stone-box .item:nth-child(2) {
    flex: 1;
    margin: 0 30px;
    background: url(../assets/images/Group\ 7.png) no-repeat center center;
    background-size: 100% 100%;
}

.section3 .stone-box .item {
    height: 189px;
    width: 35%;
    padding: 74px 0 0 0;
    box-sizing: border-box;
    font-size: 36px;
    font-weight: bold;
}

.section3 .stone-box .item .p1 {
    display: inline-block;
    width: auto;
    background: linear-gradient(89deg, #5DFEEB 0%, #E8ECEF 50%, #5DFEEB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section3 .stone-box .item .p2 {
    color: #00E0FF;
}

.section3 .stone-box .item.item:nth-child(1) {
    background: url(../assets/images/Group\ 8.png) no-repeat center center;
    background-size: 100% 100%;
}

.section3 .stone-box .item.item:nth-child(3) {
    background: url(../assets/images/Group\ 6.png) no-repeat center center;
    background-size: 100% 100%;
}

.section3 .square-box {
    margin: 41px auto 155px;
    padding: 0 5%;
    box-sizing: border-box;
}

.section3 .square-box img {
    width: 100%;
    height: auto;
}

.section3 .person-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 0;
    box-sizing: border-box;
}

.section3 .person-box .left {
    width: 320px;
}

.section3 .person-box .left img {
    width: 100%;
    height: auto;
}

.section3 .person-box .right {
    flex: 1;
    word-break: break-all;
    padding: 0 0 0 20px;
}

.section3 .person-box .right .title-sub {
    display: inline-block;
    width: auto;
    margin: 0 0 70px 0;
}

.section3 .person-box .right .title-sub .blue-line {
    margin-left: -7px;
}

.section4 {
    position: relative;
    font-size: 0;
    margin-top: -360px;
    background-size: 100% auto;
}

.section4 .video-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* max-height: 75%; */
    height: 100%;
}

.section4 .Sec4_bg2 {
    width: 100%;
    position: relative;
    z-index: 2;
    background-color: #18398f;
}

.section4 .main-box {
    background: linear-gradient(180deg, #cce3ef 0%, rgba(255, 255, 255, 0.5) 100%);
    text-align: center;
    position: relative;
    z-index: 2;
}

.section4 .box {
    /*box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);*/
    border-radius: 40px 40px 40px 40px;
    opacity: 1;
    box-sizing: border-box;
    width: 80%;
    margin: 16px auto 30px;
    font-size: 24px;
    font-weight: 400;
    color: #000000;
    line-height: 42px;
    padding: 64px 20px;
    /*background: linear-gradient(180deg, #00000011, transparent);*/
}

.section4 .box.pd0 {
    padding: 1px 20px;
}

.section4 .box p {
    margin: 80px 0;
}

.section4 .box p span {
    color: #506D8B;
    font-weight: bold;
}

.section5 {
    position: relative;
    text-align: center;
}

.section5 .main-box {

    /* position: absolute;
    left: 0;
    top: 0; */
    width: 100%;
    height: 100%;
    padding: 0 0 1px 0;
    background: url(../assets/images/Desktop\ -\ 9.png) no-repeat center center;
    background-size: 100% 100%;
    /* z-index: 2; */
}

.section5 .bg-img {
    width: 100%;
    height: auto;

}

.title-gray-box {
    display: inline-block;
    min-width: 514px;
    position: relative;
    z-index: 2;
}

.title-gray-box .title {
    font-size: 32px;
    font-weight: 700;
    color: #000000;
    letter-spacing: 10px;
}

.title-gray-box .blue-line {
    width: 100%;
    margin: 20px 0;
    border-color: #50698B;
}

.title-gray-box .blue-line::after {
    border-color: transparent #50698B transparent transparent;
}

.title-gray-box .blue-line::before {
    border-color: transparent transparent transparent #50698B;
}

.title-gray-box .desc {
    width: 100%;
    font-size: 24px;
    color: #808080;
    box-sizing: border-box;
}

.section5 {
    font-size: 0;
}

.section5 .con-box {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.section5 .con-box .item {
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.25);
    border-radius: 40px 40px 40px 40px;
    opacity: 1;
    border: 1px solid #FFFFFF;
    margin: 0 11px 150px;
    height: 504px;
    padding: 65px 0 25px;
    box-sizing: border-box;
    width: 429px;
    position: relative;
    background: #EEF0F0;
    transition: all 0.3s;
}

.section5 .con-box .wrapper:hover > .item {
    transform: translateY(-64px);
}

.section5 .con-box .wrapper:hover .title .icon {
    /*position: absolute;*/
    /*width: 99px;*/
    /*height: 99px;*/
    /*top: -30px;*/
    /*left: 30px;*/
}

.section5 .con-box .item.active {
    margin-top: -220px;
    box-shadow: 0px -10px 23px 0px rgba(0, 0, 0, 0.25);
}

.section5 .con-box .item.active .title .icon {
    position: absolute;
    width: 99px;
    height: 99px;
    top: -30px;
    left: 30px;
}

.section5 .con-box .item .title {
    font-size: 24px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 32px 0;
}

.section5 .con-box .item .title .icon {
    width: 50px;
    height: 50px;
    margin-right: 8px;
}

.section5 .con-box .item .desc {
    font-size: 24px;
    font-weight: 300;
    color: #777777;
    line-height: 48px;
    padding: 0 35px;
    box-sizing: border-box;
    text-align: left;
}

.section5 .con-box .item .desc span {
    color: #39B3F3;
}

.section5 .con-box .item .desc b {
    color: #39B3F3;
    font-size: 30px;
    font-weight: bold;
    padding: 0 10px;
}

.con-service-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    padding: 0 30px;
    margin: 0 auto 0;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.con-service-box .item {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 40px 40px 40px 40px;
    opacity: 1;
    border: 1px solid #FFFFFF;
    padding: 35px 29px 35px;
    height: auto;
    box-sizing: border-box;
    position: relative;
    background: #EEF0F0;
    margin: 0 2.5% 20px;
    flex: 1;
}

.con-service-box .item .icon {
    width: 40%;
    height: auto;
    margin: auto;
    margin-bottom: 15px;
}

.con-service-box .item .title {
    font-size: 24px;
    font-weight: 400;
    color: #50698B;
    line-height: 42px;
}

.service-main-img {
    width: 33%;
    margin: 0 auto 100px;
}

.omg-box {
    display: flex;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
    padding-left: 180px;
}

.omg-box .item {
    position: relative;
    text-align: left;
    padding: 32px 0 16px;
    margin: 16px 0;
    width: 48%;
    box-sizing: border-box;
}

.omg-box .item.h5 {
    display: none;
}

.omg-box .item:nth-child(1) {
    margin-top: -120px;
}

.omg-box .item:nth-child(2) {
    margin-top: 120px;
}

.omg-box .item:nth-child(3) {
    margin-left: -120px;
    margin-top: -220px;
}

.omg-box .item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px 40px 20px 40px;
    border: 1px solid #FFFFFF;
    transform: skewX(-20deg);
}

.omg-box .item img {
    position: absolute;
    right: 0;
    top: 14%;
    width: 284px;
    height: auto;
}

.omg-box .item .sub {
    color: #000000;
    font-size: 24px;
    margin-bottom: 22px;
    position: relative;
    z-index: 2;
}

.omg-box .item .sub.one {
    padding-left: 40px;
}

.omg-box .item .title {
    font-size: 28px;
    color: #000000;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 10px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
}

.omg-box .item .title.one {
    padding-left: 60px;
}

.omg-box .item .title.two {
    padding-left: 20px;
}

.omg-box .item .title b {
    font-size: 46px;
    color: #339EF8;
    margin-right: 15px;
}

.omg-box .item span {
    color: #50698B;
}

.section8 {
    text-align: center;
    background: #EFEFEF;
    padding: 39px 0 0px;
    box-sizing: border-box;
}

.section8 .bottom-img {
    width: 100%;
    height: auto;
}

.section8 .con-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 100px auto;
}

.section8 .con-box .item {
    margin: 40px 3%;
    width: 12%;
}

.section8 .con-box .item img {
    width: 100%;
    height: auto;
    vertical-align: middle;
}

.blue-txt {
    font-size: 32px;
    font-weight: bold;
    color: #3A95ED;
    margin: 100px;
    position: relative;
    z-index: 2;
}

.section9 .bottom-img {
    margin-top: -50px;
}

.section10 .row-box {
    display: flex;
    justify-content: space-between;
}

.section10 .logo {
    margin: 0px auto 30px;
    width: 90%;
    height: auto;
}

.section10 .row-box .col {
    text-align: left;
    width: 60%;
    padding-right: 5%;
    box-sizing: border-box;
}

.section10 .info {
    color: #000000;
    font-size: 24px;
    text-align: left;
    margin: 0 auto 30px;
    line-height: 32px;
}

.section10 .menu {
    display: flex;
    text-align: left;
    justify-content: space-between;
    width: 40%;
    margin: 0 auto 20px;
}

.section10 .menu .item {
    flex: 1;
}

.section10 .menu .item .name {
    color: #50698B;
    font-size: 28px;
    font-weight: bold;
}

.section10 .menu .item ul {
    /* margin-left: -20px; */
    line-height: 40px;
    color: #499AC5;
    font-size: 18px;
    text-decoration: underline;
}

.section10 .menu .item .link {
    cursor: pointer;
}

.section10 .copyright {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    height: 80px;
    line-height: 80px;
    margin-top: 30px;
}

@media (min-width: 1024px) {
    /* .nav_content.h5{
        display: none !important;
    } */
}

@media (max-width: 1024px) {
    .omg-box .item.h5 {
        display: block;
    }

    .omg-box .item.pc {
        display: none;
    }

    .section5 .con-box .item:hover {
        margin: 0 0 40px;
    }

    .section5 .con-box .item:hover .title .icon {
        /*position: absolute;*/
        /*width: 50px;*/
        /*height: 50px;*/
        /*top: -10px;*/
        /*left: 30px;*/
    }

    .desktopPage .Navbar_menu {
        display: block;
    }

    .desktopPage .Navbar_header {
        height: 145px;
    }

    .nav_content.pc {
        display: none;
    }

    .desktopPage .Navbar_logo {
        width: 50%;
        height: auto;
        top: 42px;
    }

    .section1 {
        height: 268px;
    }

    .section1 .left-text-box {
        font-size: 30px;
    }

    .section2 {
        padding-bottom: 80px;
    }

    .section2 .main-box {
        padding-bottom: 120px;
    }

    .section2 .bottom-img-box img {
        width: 75%;
    }

    .section3 .main-box {
        padding: 1px 0 140px 0;
    }

    .section3 .stone-box .item:nth-child(2) {
        margin: 0;
    }

    .section3 .txtbox .img1 {
        margin: 50px auto 0;
        width: 30%;
    }

    .section3 .txtbox .img2 {
        margin: 30px auto 0;
        width: 80%;
    }

    .section3 .txtbox .blue-line {
        margin: 20px auto 0;
        width: 70%;
    }

    .section3 .txtbox .img3 {
        margin: 20px auto -4px;
        width: 40%;
    }

    .section3 .txtbox .img4 {
        margin: 0 auto;
        width: 42%;
    }

    .section3 .main-img-box {
        margin-top: -90px;
    }

    .section3 .main-img-box .ball span {
        font-size: 18px;
        letter-spacing: 10px;
    }

    .section3 .main-img-box .ball.ball-center {
        margin-top: -90px;
    }

    .section3 .main-img-box .topbox .center-img {
        top: 20px;
    }

    .section3 .title-sub {
        width: 178px;
        font-size: 32px;
        margin: 20px auto 46px;
    }

    .section3 .whitetxt {
        font-size: 18px;
        letter-spacing: 8px;
        line-height: 30px;
        margin-top: 20px;
    }

    .section3 .stone-box .item {
        height: 130px;
        font-size: 20px;
        padding: 56px 0 0 0;
    }

    .section3 .square-box {
        margin: 20px 0 80px;
    }

    .section3 .person-box .right .title-sub {
        margin: 0 0 10px
    }

    .section3 .person-box {
        padding: 0 6%;
        box-sizing: border-box;
    }

    .desktopPage .sec3Text {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 2px;
    }

    .section3 .person-box .left {
        width: 200px;
    }

    .section4 {
        margin-top: -139px;
    }

    .title-gray-box .title {
        font-size: 32px;
    }

    .title-gray-box {
        min-width: 195px;
    }

    .title-gray-box .blue-line {
        margin: 13px 0;
    }

    .title-gray-box .desc {
        font-size: 14px;
    }

    .section4 .box {
        border-radius: 15px;
        margin: 20px auto 20px;
        font-size: 22px;
        line-height: 32px;
        padding: 30px 20px;
        letter-spacing: 10px;
    }

    .section4 .box p {
        margin: 40px 0;
    }

    .section4 .swiper-box {
        height: 570px;
    }

    .section5 .con-box {
        padding: 70px 0 0 0;
    }

    .section5 .con-box .item {
        width: 80%;
        display: inline-block;
        border-radius: 20px;
        margin: 0 0 40px;
        padding: 60px 20px 60px;
        height: auto;
    }

    .section5 .con-box .item .title {
        margin: 0 0 30px 0;
    }

    .section8 .con-box {
        margin: 50px 0;
    }

    .con-service-box .item {
        border-radius: 20px;
        margin: 0 10px 10px;
        padding: 30px 0;
    }

    .con-service-box .item .title {
        font-size: 26px;
        line-height: 30px;
    }

    .service-main-img {
        width: 90%;
        margin: 0 auto 50px;
    }

    .omg-box {
        margin: 50px 0;
        padding: 0;
    }

    .omg-box .item {
        width: 80%;
        margin: 0 auto 30px !important;
    }

    .omg-box .item .title {
        font-size: 30px;
    }

    .omg-box .item .sub {
        font-size: 24px;
    }

    .section10 .row-box {
        display: block;
    }

    .section10 .row-box .col {
        width: 100%;
        text-align: center;
        padding: 0;
    }

    .section10 .logo {
        width: 66%;
        margin: 0px auto 30px;
    }

    .section10 .info {
        width: 80%;
        font-size: 24px;
        line-height: 38px;
        margin: 0 auto 20px;
    }

    .section10 .menu {
        width: 80%;
        margin-top: 50px;
    }

    .section10.copyright {
        margin-top: 0;
    }

    .blue-txt {
        margin: 70px 0;
        font-size: 40px;
    }

    .nav_content {
        background: url(../assets/images/Ellipse\ 283@2x.png) no-repeat left top;
        background-size: 100% 100%;
        height: 80%;
        position: fixed;
        top: 0;
        width: 100%;
        padding: 59px 82px;
        box-sizing: border-box;

    }

    .nav_content::before {
        content: "";
        position: absolute;
        background: url(../assets/images/Ellipse\ 282@2x.png) no-repeat left top;
        background-size: 100% 100%;
        height: 100%;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
    }

    .nav_content .logo {
        position: absolute;
        z-index: 10;
        top: 58px;
        right: 50px;
        width: 80px;
        height: auto;
        opacity: 0.7;
        display: block;
    }

    .nav_content .close {
        position: absolute;
        z-index: 10;
        bottom: -80px;
        left: 50%;
        width: 80px;
        height: auto;
        transform: translateX(-50%);
        display: block;
    }

    .nav_content .Navbar_text {
        position: relative;
        left: unset !important;
        top: unset !important;
        color: #fff;
        font-weight: bold;
        margin: 0 0 30px 0;
        font-size: 22px;
    }

    svg.intro.pc {
        display: none !important;
    }

    svg.intro.app {
        height: 46%;
        display: block !important;
    }

    svg.intro.h5 {
        display: none !important;
    }

    .section1 {
        padding: 0 16%;
    }

    svg.intro.go .text {
        font-size: 24px;
    }

    .section8 .con-box .item {
        margin: 20px 2%;
        width: 14%;
    }
}

@media (max-width: 500px) {

    .omg-box .item .title.one {
        padding-left: 44px;
    }

    .omg-box .item .sub.one {
        padding-left: 28px;
    }

    .section5 .con-box .item:hover {
        margin: 0 0 40px;
    }

    .section5 .con-box .item:hover .title .icon {
        /*position: absolute;*/
        /*width: 50px;*/
        /*height: 50px;*/
        /*top: -10px;*/
        /*left: 30px;*/
    }

    svg.intro.pc {
        display: none !important;
    }

    svg.intro.app {
        display: none !important;
    }

    svg.intro.h5 {
        height: 95%;
        display: block !important;
    }

    .section1 {
        padding: 0 14%;
    }

    svg.intro.go .text {
        font-size: 14px;
    }

    .desktopPage .Navbar_menu {
        display: block;
        width: 40px;
    }

    .desktopPage .Navbar_header {
        height: 70px;
    }

    .nav_content.pc {
        display: none;
    }

    .nav_content {
        background: url(../assets/images/Ellipse\ 283@2x.png) no-repeat left top;
        background-size: 100% 100%;
        height: 60%;
        position: fixed;
        top: 0;
        width: 100%;
        padding: 39px 52px;
        box-sizing: border-box;
    }

    .nav_content::before {
        content: "";
        position: absolute;
        background: url(../assets/images/Ellipse\ 282@2x.png) no-repeat left top;
        background-size: 100% 100%;
        height: 100%;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
    }

    .nav_content .logo {
        position: absolute;
        z-index: 10;
        top: 28px;
        right: 30px;
        width: 50px;
        height: auto;
        opacity: 0.7;
        display: block;
        z-index: 5;
    }

    .nav_content .close {
        position: absolute;
        z-index: 10;
        bottom: -40px;
        left: 50%;
        width: 50px;
        height: auto;
        transform: translateX(-50%);
        display: block;
    }

    .nav_content .Navbar_text {
        position: relative;
        left: unset !important;
        top: unset !important;
        color: #fff;
        font-weight: bold;
        margin: 0 0 24px 0;
        font-size: 16px;
    }

    .desktopPage .Navbar_logo {
        width: 50%;
        height: auto;
        top: 20px;
    }

    .section1 {
        height: 110px;
    }

    .section1 .left-text-box {
        font-size: 16px;
    }

    .section1 .left-text-box .p {
        margin-bottom: 11px;
    }

    .section2 {
        padding-bottom: 40px;
    }

    .section2 .Sec2_text {
        font-size: 14px;
        line-height: 22px;
    }

    .section2 .img-center {
        width: 70%;
        height: auto;
    }

    .section2 .img-center .Sec2_img_txt {
        top: 110px;
        width: 55%;
    }

    .section2 .main-box {
        padding-bottom: 40px;
    }

    .section2 .bottom-img-box img {
        width: 75%;
    }

    .section3 .main-box {
        padding: 1px 0 150px 0;
    }

    .section3 .txtbox .img1 {
        margin: 26px auto 0;
        width: 40%;
    }

    .section3 .txtbox .img2 {
        margin: 10px auto 0;
        width: 80%;
        letter-spacing: 2px;
    }

    .section3 .txtbox .blue-line {
        margin: 12px auto 0;
        width: 60%;
        border-top: 0;
    }

    .section3 .txtbox .img3 {
        margin: 8px auto 0;
        width: 50%;
    }

    .section3 .txtbox .img4 {
        margin: 0 auto;
        width: 52%;
    }

    .section3 .main-img-box {
        margin-top: 0;
    }

    .section3 .main-img-box .ball span {
        font-size: 12px;
        letter-spacing: 4px;
    }

    .section3 .main-img-box .ball.ball-center {
        margin-top: 0;
    }

    .section3 .main-img-box .topbox .center-img {
        top: 36px;
    }

    .section3 .main-img-box .ball.ball-right {
        top: 0px;
    }

    .section3 .title-sub {
        width: 140px;
        font-size: 18px;
        margin: 10px auto 26px;
    }

    .section3 .title-sub .blue-line {
        border-top: 0;
        margin-top: 10px;
    }

    .section3 .blue-line::before {
        border: 3px solid;
        border-color: transparent transparent transparent #00F0FF;
        top: 0.3px;
    }

    .section3 .blue-line::after {
        border: 3px solid;
        border-color: transparent #00F0FF transparent transparent;
        top: 0.3px;
    }

    .section3 .stone-box .item {
        height: 60px;
        font-size: 12px;
        padding: 24px 0 0 0;
    }

    .section3 .stone-box .item:nth-child(2) {
        margin: 0 5px;
    }

    .section3 .square-box {
        margin: 20px 0 40px;
    }

    .section3 .person-box .right .title-sub {
        margin: 0 0 10px
    }

    .section3 .person-box {
        padding: 0 3%;
        box-sizing: border-box;
    }

    .desktopPage .sec3Text {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
    }

    .section3 .person-box .left {
        width: 100px;
    }

    .section3 .whitetxt {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px;
        margin-top: 10px;
    }

    .section4 {
        margin-top: -95px;
    }

    .section4 .swiper-box {
        height: 400px;
    }

    .title-gray-box .title {
        font-size: 18px;
    }

    .title-gray-box {
        min-width: 165px;
        margin: 40px auto 10px;
    }

    .blue-line::before {
        border: 3px solid;
        top: 0.3px;
    }

    .blue-line::after {
        border: 3px solid;
        top: 0.3px;
    }

    .title-gray-box .blue-line {
        margin: 7px 0;
        border-top: 0;
    }

    .title-gray-box .desc {
        font-size: 12px;
    }

    .section4 .box {
        border-radius: 10px;
        margin: 10px auto 0px;
        font-size: 12px;
        line-height: 18px;
        padding: 20px 10px !important;
        width: 85%;
        letter-spacing: 0px;
    }

    .section4 .box p {
        margin: 20px 0;
    }

    .section5 .con-box {
        padding: 40px 0 0 0;
    }

    .section5 .con-box .item {
        width: 85%;
        border-radius: 10px;
        margin: 0 auto 20px;
        padding: 39px 33px 42px;
        height: auto;
    }

    .section5 .con-box .wrapper > .item {
        transform: none !important;
        margin: 0 auto 20px !important;
    }

    .section5 .con-box .item .title {
        margin: 0 0 30px 0;
    }

    .section8 .con-box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0px auto;
    }

    .section8 .con-box .item {
        margin: 0px 3%;
        width: 20%;
    }

    .section8 .con-box .item img {
        width: 100%;
        height: auto;
        vertical-align: middle;
    }

    .section5 .con-box .item .title {
        font-size: 16px;
    }

    .section5 .con-box .item .desc {
        font-size: 12px;
        line-height: 24px;
        padding: 0;
    }

    .section5 .con-box .item .desc b {
        font-size: 14px;
        padding: 0 5px;
    }

    .con-service-box {
        margin: 20px auto 0;
        padding: 0;
        width: 85%;
    }

    .con-service-box .item {
        border-radius: 10px;
        margin: 0 1% 0;
        padding: 20px 0;
    }

    .section5 .con-box .item .title .icon {
        width: 24px;
        height: 24px;
        margin-right: 20px;
    }

    .con-service-box .item .title {
        font-size: 12px;
        line-height: 20px;
    }

    .service-main-img {
        width: 90%;
        margin: 0 auto 0px;
    }

    .omg-box {
        margin: 30px 0;
        padding: 0;
    }

    .omg-box .item {
        width: 80%;
        margin: 0 auto 20px !important;
        padding: 20px 0 10px;
    }

    .omg-box .item .title {
        font-size: 14px;
    }

    .omg-box .item .title b {
        font-size: 24px;
    }

    .omg-box .item .sub {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .omg-box .item img {
        top: 28%;
        width: 120px;
    }

    .omg-box .item::after {
        border-radius: 10px 20px;
    }

    .section8 {
        padding: 0px 0 0 0;
    }

    .section10 .logo {
        width: 50%;
        margin: 20px auto 20px;
    }

    .section10 .info {
        width: 80%;
        font-size: 12px;
        line-height: 18px;
        margin: 0 auto 10px;
    }

    .section10 .menu {
        width: 80%;
        margin-top: 20px;
    }

    .blue-txt {
        margin: 30px 0 20px;
        font-size: 16px;
    }

    .section10 .menu .item .name {
        font-size: 14px;
    }

    .section10 .menu .item ul {
        font-size: 12px;
        line-height: 26px;
        /* margin-left: -24px; */
    }

    .section10 .copyright {
        line-height: 50px;
        font-size: 12px;
        height: 50px;
        margin-top: 0;
    }

    .section3 .person-box .right {
        padding: 0;
    }
}

svg.intro.app {
    display: none;
}

svg.intro.h5 {
    display: none;
}

svg.intro {
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    /* height: 100%; */
    position: relative;
    text-align: center;
    height: 37%;
}

svg.intro .text {
    display: none;
}

svg.intro.go .text {
    /* font-size: 20px; */
    text-transform: uppercase;
    display: block;
    font-family: 'Inter-Regular-9';
}

svg.intro.go .text-stroke {
    fill: none;
    stroke: #89b9f0;
    stroke-width: 4.8px;
    stroke-dashoffset: -900;
    stroke-dasharray: 900;
    stroke-linecap: butt;
    stroke-linejoin: round;
    -webkit-animation: dash 2.5s ease-in-out forwards;
    animation: dash 2.5s ease-in-out forwards;
}

svg.intro.go .text-stroke:nth-child(2) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

svg.intro.go .text-stroke:nth-child(3) {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

svg.intro.go .text-stroke-2 {
    stroke: #576e8e;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

svg.intro.go .text-stroke:nth-child(5) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

svg.intro.go .text-stroke:nth-child(6) {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

@-webkit-keyframes dash {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    100% {
        stroke-dashoffset: 0;
    }
}

#particle-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.swiper-box {
    height: 800px;
}
